<template>
  <li class="SocialNetwork">
    <template v-if="!editMode">
      <a
        v-if="organization[type]"
        :href="formattedUrl(organization[type])"
        target="_blank"
        rel="noreferrer noopener"
        :class="`SocialList--Item SocialList--Item-${capitalize(type)}`"
        :data-ux="`Explore_OrganizationPublicPage_Contact_Social${capitalize(type)}`"
      >
        <HaIcon :icon="icon" />
        <span class="visually-hidden">
          {{
            $t(
              `organization.contact.socials.socialNetwork.${type}.labelIcon`
            )
          }}
        </span>
        <span class="visually-hidden">
          {{ $t('accessibility.openNewWindow') }}
        </span>
      </a>
    </template>
    <template v-if="editMode">
      <div :class="`Social Social-${capitalize(type)}`">
        <div class="Social--Wrapper">
          <label
            :for="type.toLowerCase()"
            :class="`Social--Label Social--Label-${capitalize(type)}`"
          >
            <HaIcon :icon="icon" />
            <span class="visually-hidden">
              {{
                $t(
                  `organization.contact.socials.socialNetwork.${type}.labelEdit`
                )
              }}
            </span>
          </label>
          <div class="Social--Field">
            <template v-if="(!editMode || hasValue) && !toUpdate">
              <p class="Social--Text">
                {{ value }}
              </p>
            </template>
            <template v-else>
              <HaValidatorInput
                v-slot="{ validated, valid, errors }"
                v-model="value"
                name="socialNetworkUrl"
                rules="url"
                slim
              >
                <HaInputGroup v-if="!hasValue || toUpdate">
                  <HaInput
                    :id="type.toLowerCase()"
                    v-model="value"
                    name="socialNetworkUrl"
                    :is-valid="validated ? valid : null"
                    :placeholder="
                      $t(
                        `organization.contact.socials.socialNetwork.${type}.placeholder`
                      )
                    "
                  />
                  <ha-button
                    :data-ux="`Explore_OrganizationPublicPage_EditMode_Contact_Save${capitalize(
                      type
                    )}`"
                    :loading="isLoading"
                    :disabled="isLoading"
                    @click="save()"
                  >
                    <template #icon>
                      <HaIcon :icon="faFloppyDisk" />
                      <span class="visually-hidden">
                        {{ $t('button.save') }}
                      </span>
                    </template>
                  </ha-button>
                </HaInputGroup>
                <span
                  v-if="errors.length"
                  class="Social--Error"
                >
                  {{ errors[0] }}
                </span>
              </HaValidatorInput>
            </template>
          </div>
        </div>
        <ha-dropdown
          v-if="editMode && hasValue && !toUpdate"
          alignment="right"
        >
          <template #trigger>
            <ha-button
              class="Social--Edit"
              variant="flat"
              color="basic"
              :data-ux="`Explore_OrganizationPublicPage_EditMode_Contact_Toggle_Dropdown${capitalize(
                type
              )}`"
            >
              <HaIcon :icon="faEllipsisVertical" />
              <span class="visually-hidden">{{
                $t('organization.contact.openSettings')
              }}</span>
            </ha-button>
          </template>
          <template #content>
            <ha-menu-list>
              <ha-menu-list-item
                :icon="faPen"
                :label="$t('button.update')"
                :data-ux="`Explore_OrganizationPublicPage_EditMode_Contact_Update${capitalize(
                  type
                )}`"
                @click="update()"
              />
              <ha-menu-list-item
                :icon="faTrash"
                :label="$t('button.delete')"
                :data-ux="`Explore_OrganizationPublicPage_EditMode_Contact_Delete${capitalize(
                  type
                )}`"
                highlighted
                @click="remove()"
              />
            </ha-menu-list>
          </template>
        </ha-dropdown>
      </div>
    </template>
  </li>
</template>

<script>
import {
  faFloppyDisk,
  faEllipsisVertical,
  faPen,
  faTrash,
  faDesktop
} from '@fortawesome/pro-solid-svg-icons'
import {
  faFacebookF,
  faInstagram,
  faXTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import { capitalizeFirstLetter } from '@/helpers'
import useEditMode from '@/composables/useEditMode'

import {
  HaButton,
  HaDropdown,
  HaInput,
  HaInputGroup,
  HaMenuList,
  HaMenuListItem,
  HaIcon,
  useNotifications,
  HaValidatorInput
} from '@ha/components-v3'
import useSegment from '@/composables/useSegment'
import { useI18n } from '#imports'

export default {
  name: 'SocialNetwork',
  components: {
    HaButton,
    HaDropdown,
    HaInput,
    HaInputGroup,
    HaMenuList,
    HaMenuListItem,
    HaValidatorInput,
    HaIcon
  },
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { pushNotification } = useNotifications()
    const { trackUpdateOrganization } = useSegment()
    const { editMode } = useEditMode()
    const i18n = useI18n()
    return {
      pushNotification,
      trackUpdateOrganization,
      editMode,
      i18n
    }
  },
  data() {
    return {
      value: null,
      toUpdate: false,
      isLoading: false,
      faFloppyDisk,
      faEllipsisVertical,
      faPen,
      faTrash
    }
  },
  computed: {
    organization() {
      return this.$store.state.organizations.organization
    },
    hasValue() {
      return this.organization[this.type]
    },
    icon() {
      const icons = {
        webSite: faDesktop,
        facebookPage: faFacebookF,
        twitterPage: faXTwitter,
        instagramPage: faInstagram,
        youtubePage: faYoutube
      }

      return icons[this.type]
    }
  },
  watch: {
    organization: {
      deep: true,
      handler() {
        if (!this.value) {
          this.value = this.organization[this.type]
        }
      }
    }
  },
  methods: {
    update() {
      this.toUpdate = true
    },
    save() {
      this.isLoading = true

      const payload = {
        address: this.organization.address,
        category: this.organization.category,
        city: this.organization.city,
        description: this.organization.description,
        displayCoordinates: this.organization.displayCoordinates,
        email: this.organization.email,
        facebookPage: this.organization.facebookPage,
        geoLocation: this.organization.geoLocation,
        instagramPage: this.organization.instagramPage,
        longDescription: this.organization.longDescription,
        phone: this.organization.phone,
        twitterPage: this.organization.twitterPage,
        webSite: this.organization.webSite,
        youtubePage: this.organization.youtubePage,
        zipCode: this.organization.zipCode
      }

      payload[this.type] = this.value
      this.trackUpdateOrganization(
        this.organization.organizationSlug,
        this.organization,
        payload
      )

      this.$store
        .dispatch('organizations/saveOrganizationData', {
          slug: this.organization.organizationSlug,
          payload
        })
        .then(() => {
          this.toUpdate = false
          this.pushNotification({
            type: 'success',
            title: this.$t('toast.infoUpdate.title'),
            body: this.$t('toast.infoUpdate.body'),
            timeout: 5000
          })
        })
        .catch((error) => {
          this.pushNotification({
            type: 'danger',
            title: this.$t(
              `error.code.${error.response.status}.title`
            ),
            body: this.$t(
              `error.code.${error.response.status}.message`
            ),
            timeout: 5000
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    remove() {
      this.value = ''
      const payload = {
        address: this.organization.address,
        category: this.organization.category,
        city: this.organization.city,
        description: this.organization.description,
        displayCoordinates: this.organization.displayCoordinates,
        email: this.organization.email,
        facebookPage: this.organization.facebookPage,
        geoLocation: this.organization.geoLocation,
        instagramPage: this.organization.instagramPage,
        longDescription: this.organization.longDescription,
        phone: this.organization.phone,
        twitterPage: this.organization.twitterPage,
        webSite: this.organization.webSite,
        youtubePage: this.organization.youtubePage,
        zipCode: this.organization.zipCode
      }

      payload[this.type] = this.value

      this.$store
        .dispatch('organizations/saveOrganizationData', {
          slug: this.organization.organizationSlug,
          payload
        })
        .then(() => {
          this.pushNotification({
            type: 'success',
            title: this.$t('toast.infoDelete.title'),
            body: this.$t('toast.infoDelete.body'),
            timeout: 5000
          })
        })
        .catch((error) => {
          this.pushNotification({
            type: 'danger',
            title: this.$t(
              `error.code.${error.response.status}.title`
            ),
            body: this.$t(
              `error.code.${error.response.status}.message`
            ),
            timeout: 5000
          })
        })
    },
    formattedUrl(url) {
      const pattern = /^((http|https|ftp):\/\/)/
      if (!pattern.test(url)) {
        return `https://${url}`
      }
      return url
    },
    capitalize(value) {
      return capitalizeFirstLetter(value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/social-network';
</style>

<style lang="scss" scoped>
.Social {
  input {
    text-overflow: ellipsis;
  }
}

:deep(.HaInputGroup > *) {
  flex: initial;
}
</style>
